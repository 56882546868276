import React, { useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import '../form/form.css'

const Form = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [uploadFile, setUploadFile] = useState({})
    const [loading, setLoading] = useState(false);
    // const eventUrl = 'http://103.41.205.87:80/event/';
    const eventUrl = 'https://superstudent.niecindonesia.com/event/';
    const eventToken = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'
    
    const onSubmit = data => {
        data = {...data, 
            berkas: data.berkas[0], 
            file_name_berkas: data.berkas[0].name,
            // file_bukti_pembayaran: data.file_bukti_pembayaran[0],
            // file_name_bukti_pembayaran: data.file_bukti_pembayaran[0].name,
        }
        // console.log('dataaa',data);
        // setLoading(true);
        axios.post(eventUrl+'arnantyoscholarship', data, {
            headers: {
                'Authorization': `Bearer ` + eventToken,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(res=>{
            setLoading(false);
            Swal.fire({
                title: 'Pendaftaran Beasiswa Berhasil!',
                text: 'Tunggu Hasil Pengumuman Interview Pada Tanggal 8 Mei 2024',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then((result)=>{
                // if (result.isConfirmed) {
                //     window.location.reload(false)
                // }
            })
        })
        .catch(err=>console.log(err))
        // console.log(data);
    }

    // const handleUpload = (e) => {
    //     const file = e.target.files[0]
    //     setUploadFile({...uploadFile, file})
    // }

  return (
    <div className='form'>
        <h4>Form Pendaftaran</h4>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-input">
                <input {...register("nama", {required:'Nama Wajib Diisi'})}  type="text" className={`form-control ${errors.nama?.message && 'is-invalid'}`} id="name" placeholder="Nama"/>
                {errors.nama?.message && (<div className="invalid-feedback">{errors.nama?.message}</div>) }
            </div>
            <div className="form-input">
                <input {...register("email", {required:'Email Wajib diisi', pattern: {value:/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, message: 'Email tidak valid'}})} type="text" className={`form-control ${errors.email?.message && 'is-invalid'}`} id="email" placeholder="Email Address"/>
                {errors.email?.message && (<div className="invalid-feedback">{errors.email?.message}</div>) }
            </div>
            <div className="form-input">
                <input {...register("notelp", {required: 'Nomor Telepon Wajib Diisi'})} type="number" className={`form-control ${errors.notelp?.message && 'is-invalid'}`} id="notelp" placeholder="No Handphone"/>
                {errors.notelp?.message && (<div className="invalid-feedback">{errors.notelp?.message}</div>) }
            </div>
            <div className="form-input">
                <input {...register("sekolah", {required: 'Nama Sekolah Wajib Diisi'})} type="text" className={`form-control ${errors.sekolah?.message && 'is-invalid'}`} id="sekolah" placeholder="Sekolah"/>
                {errors.sekolah?.message && (<div className="invalid-feedback">{errors.sekolah?.message}</div>) }
            </div>
            <div className="form-input">
                <input {...register("username_ig")} type="text" className={`form-control ${errors.username_ig?.message && 'is-invalid'}`} id="username_ig" placeholder="Username Instagram"/>
                {errors.username_ig?.message && (<div className="invalid-feedback">{errors.username_ig?.message}</div>) }
            </div>
            {/* <div className="form-input">
                <input {...register('file_bukti_pembayaran', 
                    {required: 'Berkas Wajib Diisi', validate:{
                        size: (files) => files[0]?.size < 5000000 || "Ukuran berkas maksimal 5mb"}
                    })} 
                accept=".zip,.rar,.7zip,.pdf,.jpg,.png,.jpeg"
                className={`form-control ${errors.file_bukti_pembayaran?.message && 'is-invalid'}`} type="file" id="formFile" />
                {errors.file_bukti_pembayaran?.message && (<div className="invalid-feedback">{errors.file_bukti_pembayaran?.message}</div>) }
            </div> 
            <p>*Upload bukti transfer registrasi 500K</p> */}
            <div className="form-input">
                <input {...register('berkas', 
                    {required: 'Berkas Wajib Diisi', validate:{
                        size: (files) => files[0]?.size < 2000000 || "Ukuran berkas maksimal 2mb",
                        acceptedFormats: (files) => ["application/x-zip-compressed", "application/pdf"].includes(files[0]?.type) || "Berkas harus berupa .zip atau .pdf"}
                    })} 
                accept=".zip,.rar,.7zip,.pdf"
                className={`form-control ${errors.berkas?.message && 'is-invalid'}`} type="file" id="formFile" />
                {errors.berkas?.message && (<div className="invalid-feedback">{errors.berkas?.message}</div>) }
            </div>
            <p>*Upload berkas sesuai syarat dibawah (Pastikan semua berkasmu telah menjadi satu file *pdf atau *zip)</p>
            <div className="form-input">
                {
                    !loading ? (<button className='btn'>Daftar</button>) : (<button className='btn disabled'>Daftar...</button>)
                }
            </div>
        </form>
    </div>
  )
}

export default Form