import React, { useEffect, useState } from 'react'
import '../navbar/navbar.css'

const Navbar = () => {
    const [clicked, setClicked] = useState(false)
    const [stickyClass, setStickyClass] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 70 ? setStickyClass('active') : setStickyClass('');
    }
  };
    const handleClick = () => {
        setClicked(!clicked)
    }
  return (
    <>
    <div className={`navbar ${clicked ? 'active' : ''} ${stickyClass}`}>
        <div className="container">
        <a href="/">
            <h2 className={`nav-brand ${clicked ? 'active' : ''} ${stickyClass}`}>Arnantyo X IHMS Scholarship</h2>
        </a>
        <div>
            <ul className={`nav-list ${clicked ? '' : 'active'}`}>
                <li><a href="#header" className={`navlink ${stickyClass}`}>Home</a></li>
                <li><a href="#info-beasiswa" className={`navlink ${stickyClass}`}>Info Beasiswa</a></li>
                <li><a href="#tahapan" className={`navlink ${stickyClass}`}>Tahapan</a></li>
            </ul>
        </div>
        <div className={`burger-menu ${stickyClass} ${clicked ? 'active' : ''}`} onClick={handleClick}>
            <i className={`fa-solid ${clicked ? 'fa-xmark' : 'fa-bars'}`}></i>
        </div>
        </div>
    </div>
    </>
  )
}

export default Navbar