import React from 'react'
import Navbar from "../components/navbar/Navbar";
import '../style.css'
import Form from '../components/form/Form';

const Header = () => {
  return (
    <section id='header'>
      <Navbar/>
      <div className="container">
      <div className="hero">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="text">
            <div className="text1">
              <h1>Pendidikan Vocational Berbasis International di Bidang Management Perhotelan</h1>
              <h2>Intake July 2024</h2>
              <p>Dapatkan Beasiswa IHMS Penuh 100% Sekarang + Berkesempatan mendapat beasiswa 50% di Melbourne Polytechnic Australia</p>
                <a href="https://wa.me/6281239483761">
                <button className='btn'>Konsultasikan Sekarang</button>
                </a>
                <a href="#persyaratan" className='btn2'>
                  <button className='btn'>Persyaratan Berkas</button>
                </a>
            </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
          <section id='daftar'>
            <Form/>
          </section>
          </div>
        </div>
      </div>
      </div>
    </section>
  )
}

export default Header