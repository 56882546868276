import React from 'react'

const Footer = () => {
  return (
    <>
    <section id='footer'>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="contact">
                        <h4 className='title1'>Contact Us</h4>
                        <a href="tel:+6281239483761">081 239 483 761 /</a><br />
                        <a href="tel:+6281567892333">0815 6789 2333</a><br />
                        <a href="mailto:ihms.bali@gmail.com">ihms.bali@gmail.com</a>
                        <div className="sosmed">
                            <a href="https://www.instagram.com/ihms.bali/" target='_blank' rel="noopener noreferrer"><i className="fa-brands fa-instagram" target='_blank' rel="noopener noreferrer"></i></a>
                            <a href="https://www.facebook.com/ihmsindonesia" target='_blank' rel="noopener noreferrer"><i className="fa-brands fa-facebook"></i></a>
                            <a href="https://ihmscampus.com/" target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-globe"></i></a>
                            <a href="https://www.tiktok.com/@ihms.bali" target='_blank' rel="noopener noreferrer"><i className="fa-brands fa-tiktok"></i></a>
                            <a href="https://www.linkedin.com/company/international-hotel-management-school-ihms/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-md-6">
                    <div className="ihms">
                        <h4 className='title2'>IHMS</h4>
                        <p>Jl. Buluh Indah N0.56, Pemecutan Kaja, Kec. Denpasar Utara, Kota Denpasar, Bali 80118</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="copyright">
        <p>Copyright &copy; 2023 IHMS</p>
    </div>
    </>
  )
}

export default Footer