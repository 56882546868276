import Footer from "./section/Footer";
import Header from "./section/Header";
import InfoBeasiswa from "./section/InfoBeasiswa";
import LearnMore from "./section/LearnMore";
import Tahapan from "./section/Tahapan";


function App() {
  return (
    <>
      <Header/>
      <InfoBeasiswa/>
      <Tahapan/>
      <LearnMore/>
      <Footer/>
    </>
  );
}

export default App;
