import React from 'react'
import '../style.css'
import CardTahap from '../components/card-tahap/CardTahap'

const Tahapan = () => {
    const tahap = [
        {title: 'Tahap I Pengumpulan Berkas', tgl: '21 Februari - 30 April 2024'},
        {title: 'Tahap II Interview', tgl: '8 Mei 2024'},
        {title: 'Tahap III Pengumuman Pemenang', tgl: '18 Mei 2024'},
    ]
    // const handleClick = () => {
    //     window.scrollTo(0, 0)
    // }
  return (
    <section id='tahapan'>
        <div className="container">
            <div className="daftar">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="cara-daftar">
                            <h2>Cara Daftar?</h2>
                            <p>Untuk pendaftaran, kamu bisa isi form registrasi yang terletak di atas halaman ini, kemudian upload dan kirim berkasmu sebelum tanggal 30 April 2024. Kamu akan menerima follow up via Whatsapp dari kami disetiap kelolosan tahap. Untuk informasi lebih lanjut kamu bisa hubungi ke nomor <a href="tel:+6281239483761">081 239 483 761</a> atau follow Instagram kami <a href="https://www.instagram.com/ihms.bali/">@ihms.bali</a></p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <section id='persyaratan'>
                        <div className="box">
                            <div className="header">
                                <h4>Persyaratan Berkas</h4>
                            </div>
                            <div className="body">
                                <ol>
                                    <li>Ijazah SMA/SMK/Pendidikan Terakhir</li>
                                    <li>Nilai Rapor SMA/SMK Kelas 12 atau Transkrip Nilai Kuliah</li>
                                    <li>CV in English</li>
                                    <li>Motivation Letter in English</li>
                                    {/* <li>Melakukan Pembayaran Registrasi 500k ke <a href="http://register.ihmscampus.com" target="_blank" rel="noopener noreferrer">register.ihmscampus.com</a></li> */}
                                    <li>Screenshot bukti follow IG @ihms.bali & @arnantyo</li>
                                </ol>
                            </div>
                        </div>
                        </section>
                    </div>
                </div>
            </div>
            <div className='mid-btn'>
                <a href="#daftar"><button className='btn'>Daftar Sekarang</button></a>
            </div>
            <div className="tahap">
                <div className="row justify-content-center">
                    {tahap.map((t, i)=>{
                        return (
                        <div key={i} className="col-lg-4">
                            <CardTahap title={t.title} body={t.tgl}/>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </section>
  )
}

export default Tahapan